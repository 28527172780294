<template lang="pug">
.testimonials-container(v-if="block")
  h3(v-html="block.title")
  |
  .testimonials(v-if="block.testimonials?.length" ref="carousel")
    .testimonial(
      v-for="(testimonial, index) in block.testimonials"
      :key="index"
    )
      .text {{ testimonial.text }}
      |
      .author {{ testimonial.author_name }}
      |
      .source {{ testimonial.author_role }},
      |
      .source {{ testimonial.author_company }}
  |
  .arrow-left(@click="scrollLeft")
    .arrow
      svg(xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31" fill="none")
        path(d="M19.25 23.334L11.75 15.834L19.25 8.33398" stroke-width="3" stroke-linecap="round" stroke-linejoin="round")
  |
  .arrow-right(@click="scrollRight")
    .arrow
      svg(xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31" fill="none")
        path(d="M11.75 8.33398L19.25 15.834L11.75 23.334" stroke-width="2.4" stroke-linecap="round" stroke-linejoin="round")
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import type { TBlock } from "@/models/block";

interface IProps {
  block?: TBlock | null;
}

const props = withDefaults(defineProps<IProps>(), {
  block: null,
});

const carousel = ref<HTMLDivElement | null>(null);
let items: HTMLElement[] = [];
let currentIndex = 0;

const scrollToIndex = (index: number) => {
  if (!carousel.value || !items.length) return;

  currentIndex = index;

  const target = items[index];
  const offset = target.offsetLeft - (carousel.value.offsetWidth - target.offsetWidth) / 2;

  carousel.value.scrollTo({
    left: offset,
    behavior: "smooth",
  });
};

const scrollLeft = () => {
  if (currentIndex > 0) {
    scrollToIndex(currentIndex - 1);
  } else {
    scrollToIndex(items.length - 2);
    setTimeout(() => scrollToIndex(items.length - 3), 300);
  }
};

const scrollRight = () => {
  if (currentIndex < items.length - 1) {
    scrollToIndex(currentIndex + 1);
  } else {
    scrollToIndex(1);
    setTimeout(() => scrollToIndex(2), 300);
  }
};

onMounted(() => {
  if (!carousel.value) return;

  const children = Array.from(carousel.value.children) as HTMLElement[];
  items = [...children];

  const firstClone = children[0].cloneNode(true) as HTMLElement;
  const lastClone = children[children.length - 1].cloneNode(true) as HTMLElement;

  carousel.value.appendChild(firstClone);
  carousel.value.insertBefore(lastClone, children[0]);

  items = Array.from(carousel.value.children) as HTMLElement[];

  scrollToIndex(1);
});

</script>

<style lang="sass" scoped>
.testimonials-container
  padding-top: 100px
  padding-bottom: 100px
  position: relative
  overflow: hidden

  h3
    padding-bottom: 80px
    color: #034033
    text-align: center
    font-size: 40px
    font-style: normal
    font-weight: 800
    line-height: 47px

  .arrow
    display: inline-flex
    height: 48px
    width: 48px
    justify-content: center
    align-items: center
    border-radius: 50%
    background: var(--limegreen-100, #CEFB74)
    cursor: pointer
    position: absolute
    top: 60%
    transform: translateY(-50%)
    z-index: 3
    transition: background 0.3s ease

    path
      stroke: #074033

    &:hover
      background: var(--limegreen-200, #B5E664)

    &-left
      position: absolute
      top: 0
      height: 100%
      z-index: 3
      left: 0
      padding: 26px

      .arrow
        left: 26px

      &:before
        content: ""
        position: absolute
        bottom: 0
        height: 100%
        left: 0
        width: 100px
        background: linear-gradient(270deg, rgba(247, 250, 242, 0.00) -154.51%, #F7FAF2 71.07%)

    &-right
      position: absolute
      top: 0
      height: 100%
      z-index: 3
      right: 0
      padding: 26px

      .arrow
        right: 26px

      &:before
        content: ""
        position: absolute
        bottom: 0
        height: 100%
        right: 0
        width: 100px
        background: linear-gradient(90deg, rgba(247, 250, 242, 0.00) -154.51%, #F7FAF2 71.07%)

  .testimonials
    display: flex
    gap: 67px
    position: relative
    overflow-x: auto
    scroll-behavior: smooth
    -ms-overflow-style: none
    scrollbar-width: none

    &::-webkit-scrollbar
      display: none

    .testimonial
      flex: 0 0 auto
      width: 455px
      color: #034033

      .text
        font-size: 20px
        font-weight: 600
        line-height: 145%

      .author
        font-weight: 700
        margin-top: 32px
        margin-bottom: 8px

      .source
        font-weight: 500

@media (max-width: 768px)
  .testimonials-container
    padding-top: 60px
    padding-bottom: 20px

    h3
      padding-bottom: 56px
      width: auto
      font-size: 24px
      line-height: 29px
      padding-right: 20px
      padding-left: 20px

    .arrow
      display: none

      &-left
        &:before
          background: transparent
          width: 60px
          height: 80%
          bottom: 0

      &-right
        &:before
          background: transparent
          width: 60px
          height: 80%
          bottom: 0
      
    .testimonials
      gap: 32px

      .testimonial
        width: 274px
        font-size: 14px
        line-height: 20px

        .text
          font-size: 16px
          line-height: 23px

        .author
          font-weight: 700
          margin-top: 24px

        .source
          font-weight: 500
          margin-top: 8px
</style>